<template>
	<app-layout v-loading="pageLoaing" :sbdx="true">
		<el-row slot="header" type="flex" justify="space-between;" class="judgeBoxs">
			<el-col :span="4" style="text-align: left">
				<el-button size="medium" style="transform: translateX(10px); color: #000" type="text" @click="$router.back()">返回</el-button>
			</el-col>
			<el-col :span="16" class="title" style="text-align: center; display: flex; align-items: center; justify-content: center">
				<h4>{{ title }}</h4>
			</el-col>
			<el-col :span="4"></el-col>
		</el-row>
		<div class="rating-page">
			<article class="rating-page-left">
				<div class="section">
					<h1 class="title-icon">职级评估</h1>
					<section class="rating-item" style="margin-top: 20px">
						<el-form inline label-width="120px">
							<el-row>
								<el-col :span="9" :offset="2">
									<el-form-item class="inline-form-item" label="职族">
										<el-select
											size="small"
											@change="
												(val) => {
													changePosition('职族', val);
												}
											"
											class="select-item"
											v-model="officeInfo.familyId"
										>
											<el-option
												v-for="(item, i) in positionFamilyList"
												:key="i"
												:value="item.id"
												:label="item.name"
											></el-option>
										</el-select>
									</el-form-item>
								</el-col>
								<el-col :span="9" :offset="3">
									<el-form-item class="inline-form-item" label="职级代码">
										<el-select
											size="small"
											:disabled="!officeInfo.familyId"
											class="select-item"
											:value="officeInfo.officeCode"
											@focus="showPicker()"
											:popper-append-to-body="false"
											popper-class="hidden-popper"
										></el-select>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="9" :offset="2">
									<el-form-item class="inline-form-item" label="职级">
										<el-select
											size="small"
											:disabled="!officeInfo.familyId"
											:value="officeInfo.level"
											class="select-item disable-input__suffix"
											popper-class="hidden-popper"
										></el-select>
									</el-form-item>
								</el-col>
								<el-col :span="9" :offset="3">
									<el-form-item class="inline-form-item" label="岗位">
										<el-select size="small" :disabled="!officeInfo.officeCode" v-model="levelAndCodeId" class="select-item">
											<el-option
												v-for="(item, i) in positionList"
												:key="i"
												:value="item.levelAndCodeId"
												:label="item.jobName"
											></el-option>
										</el-select>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row v-show="officeInfo.dutyIllustrate">
								<el-col :span="20" :offset="2">
									<el-form-item label="职责描述" class="dutyIllustrate">
										<span>{{ officeInfo.dutyIllustrate }}</span>
									</el-form-item>
								</el-col>
							</el-row>
						</el-form>
						<!--<div style="margin-left: 17.5%;">
              <label
                style="
                vertical-align: middle;
                font-size: 14px;
                color: #606266;
                line-height: 40px;
                padding: 0 12px 0 0;"
              >职责描述</label>
              <p
                style="color:#606266; line-height: 1.5em; font-size:14px;"
              >{{officeInfo.dutyIllustrate}}</p>
            </div>!-->
					</section>
					<div class="other-evaluations" v-if="$route.params.type === 'again' && otherEvaluationList && otherEvaluationList.length > 0">
						<h4 style="margin: 12px 0">其他人的评价</h4>
						<el-table
							:data="otherEvaluationList"
							stripe
							style="width: 80%"
							:header-cell-style="{ background: '#eef1f6', color: '#606266', textAlign: 'center' }"
							border
						>
							<el-table-column label="评价人" width="120px" align="center" prop="assessorName"></el-table-column>
							<el-table-column label="得分" width="120px" align="center" prop="score"></el-table-column>
							<el-table-column label="职等" width="120px" align="center" prop="jobEtc"></el-table-column>
							<el-table-column label="职级代码" width="120px" align="center" prop="officeCode"></el-table-column>
							<el-table-column label="岗位" align="center" width="120px" prop="jobName"></el-table-column>
							<el-table-column label="附件" width="260px">
								<template v-slot="{ row }">
									<a :key="i" v-for="(file, i) in row.fileList || []" :href="file.url || file.httpUrl" class="link-text fj-icon"
										><span>{{ file.name || file.fileName }}</span></a
									>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<el-dialog :visible.sync="dialogVisible" center title="职级代码-职级" top="20px">
						<el-table
							border
							max-height="500"
							class="radio-table"
							v-loading="tableLoading"
							:data="positionLevelList"
							ref="Table"
							:row-key="getRowKey"
							highlight-current-row
							@select="handleSelectChange"
							stripe
						>
							<el-table-column type="selection" :reserve-selection="true" width="60" align="center"></el-table-column>
							<el-table-column width="90" align="center" label="职级代码" prop="officeCode"></el-table-column>
							<el-table-column width="90" align="center" label="职级" prop="level"></el-table-column>
							<el-table-column label="职责说明" align="center" prop="dutyIllustrate"></el-table-column>
						</el-table>
						<div slot="footer">
							<el-button @click="dialogVisible = false">取消</el-button>
							<el-button type="primary" @click="selectPositoinLevel(selectList)">确认</el-button>
						</div>
					</el-dialog>
				</div>
				<div class="section">
					<h1 class="title-icon">职等评估</h1>
					<section class="rating-item" v-for="(item, i) in formData" :key="i" style="padding: 0 14%">
						<h2 class="title" v-if="item.className">{{ (i + 1) | numToCN }}、{{ item.className }}</h2>
						<div v-for="(problem, ci) in item.indicatorList" :key="ci">
							<h2 class="title" v-if="!item.className">{{ (i + 1) | numToCN }}、{{ problem.indicatorName }}</h2>
							<h3 class="title" v-else>{{ ci + 1 }}、{{ problem.indicatorName }}</h3>
							<el-radio-group class="radio-group" v-model="problem.selectedOptionId">
								<el-radio
									:key="oi"
									@click.native="changeRadio(option, problem)"
									class="radio-item"
									v-for="(option, oi) in problem.optionList"
									:label="option.optionId"
									>{{ option.content }}</el-radio
								>
							</el-radio-group>
							<template v-if="$route.params.type === 'self'">
								<p>
									举例说明
									<i v-if="problem.requiredExample" style="color: red; padding: 0 8px">(必填)</i>
								</p>
								<el-input
									class="for-exemple"
									type="textarea"
									resize="none"
									maxlength="250"
									show-word-limit
									v-model="problem.reason"
									:autosize="{ minRows: 4, maxRows: 4 }"
									rows="4"
								></el-input>
								<el-upload
									:file-list="problem.nativeFileList"
									:limit="3"
									:on-exceed="handleExceed"
									multiple
									name="imgFile"
									:before-upload="handleBeforeUpload"
									:on-success="
										(res, file, filelist) => {
											handleUploaded(res, file, filelist, problem);
										}
									"
									:on-remove="
										(file, filelist) => {
											handleRemoveFile(file, filelist, problem);
										}
									"
									:action="FileUploadAction"
								>
									<el-button type="text" class="el-icon-circle-plus">添加附件</el-button>
								</el-upload>
							</template>
							<div v-if="$route.params.type === 'again' && problem.otherEvaluationList && problem.otherEvaluationList.length > 0">
								<h4 style="margin: 12px 0">其他人的评价</h4>
								<el-table
									:data="problem.otherEvaluationList"
									stripe
									style="width: 92.2%"
									:header-cell-style="{ background: '#eef1f6', color: '#606266', textAlign: 'center' }"
									border
								>
									<el-table-column label="评价人" width="120px" align="center" prop="assessorName"></el-table-column>
									<el-table-column label="得分" align="center" width="120px" prop="score"></el-table-column>
									<el-table-column label="选择项" prop="content"></el-table-column>
									<el-table-column label="举例说明" prop="reason"></el-table-column>
									<el-table-column label="附件" width="260px">
										<template v-slot="{ row }">
											<a :key="i" v-for="(file, i) in row.fileList || []" @click="downFile(file)" class="link-text fj-icon">
												<span>{{ file.name || file.fileName }}</span></a
											>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</div>
					</section>
				</div>
				<div class="section reply-report" v-if="$route.params.type === 'self'">
					<h1 class="title-icon">自述答辩报告</h1>
					<section class="rating-item">
						<el-form style="padding: 0 14%">
							<el-form-item label="附件">
								<el-upload
									:file-list="nativeFileList"
									:limit="3"
									multiple
									:on-exceed="handleExceed"
									name="imgFile"
									:before-upload="handleBeforeUpload"
									:on-success="
										(res, file, filelist) => {
											handleUploaded(res, file, filelist, { fileList: accessoryUrl });
										}
									"
									:on-remove="
										(file, filelist) => {
											handleRemoveFile(file, filelist, { fileList: accessoryUrl });
										}
									"
									:action="FileUploadAction"
								>
									<el-button type="primary" size="small">上传文件</el-button>
								</el-upload>
								<div style="margin-left: 40px" class="el-upload__tip">文件格式为建议上传ppt格式，大小在10MB以内</div>
								<div class="file-pre-download" :key="i" v-for="(item, i) in evaluationTemplates">
									<img src="../../assets/DOC.png" width="35px" height="35px" />
									<div>
										<p class="file-name">{{ item.groupName }}</p>
										<a class="text-high-light" :href="item.dictName">下载</a>
									</div>
								</div>
							</el-form-item>
						</el-form>
					</section>
				</div>
			</article>
			<!-- <el-dialog title="选择职级"
                 top="20px"
                 :visible.sync="dialogVisible">
        <table-position-levels @confirm="selectPositionLevel"
                               style="height:80vh;"
                               :show="dialogVisible"
                               @cancel="dialogVisible = false;"></table-position-levels>
      </el-dialog>-->
		</div>
		<div slot="footer" style="text-align: center">
			<el-button @click="$router.back()">取消</el-button>
			<el-button type="primary" :disabled="uploadQueue.length > 0" @click="save">保存</el-button>
			<el-button @click="submit" :disabled="uploadQueue.length > 0" type="primary">提交</el-button>
		</div>
	</app-layout>
</template>
<script>
import { getEvaluationForm, saveEvaluation, getPositionLevelList, submitEvaluation } from '../../api/staffSystem';
import { makePositionLevelTxt } from '../../utils/index';
import { FileUploadAction, RequiredError } from '../../utils/const';
import { getEvaluationTemplates } from '../../utils/dict';
import * as Position from '../../api/position';
export default {
	async mounted() {
		this.id = this.$route.query.id;
		if (!this.id) {
			return;
		}
		this.pageLoaing = true;
		try {
			if (this.$route.params.type === 'self') {
				this.evaluationTemplates = getEvaluationTemplates();
			}

			await this.getEvaluationForm(this.id);
			await this.getPositionFamilyList({ staffResultId: this.staffResultId });
			if (this.officeInfo && Object.values(this.officeInfo).filter(Boolean).length > 0) {
				this.getPositionList({
					familyId: this.officeInfo.familyId,
					level: this.officeInfo.level,
					officeCode: this.officeInfo.officeCode
				});
				this.$nextTick(() => {
					this.$set(this.officeInfo, 'familyId', this.officeInfo.familyId);
				});
			}
		} catch {
			this.pageLoaing = false;
		} finally {
			this.pageLoaing = false;
		}
	},
	computed: {
		title() {
			return `${this.$route.query.employeeName}-人岗评估`;
		}
	},
	data() {
		return {
			id: '',
			FileUploadAction,
			submitting: false,
			dialogVisible: false,
			pageLoaing: false,
			otherProblem: {},
			evaluationTemplates: [],
			staffResultId: null,
			positionLevelParams: {
				pageNo: 1,
				pageSize: 500
			},
			reason: '',
			officeInfo: {},
			otherEvaluationList: [],
			accessoryUrl: [],
			nativeFileList: [],
			formData: [],
			positionLevelDatas: [],
			positionFamilyList: [],
			positionLevelList: [],
			positionList: [],
			selectList: [],
			positionLevelTxt: '',
			levelAndCodeId: null,
			tableLoading: false,
			uploadQueue: [],
			positonFamilyId: null
		};
	},
	methods: {
		async showPicker() {
			this.dialogVisible = true;
			this.selectList = [];
			this.$nextTick(() => {
				this.$refs.Table.clearSelection();
			});
			this.tableLoading = true;
			try {
				await this.getPositionLevelListByFamily({
					familyId: this.officeInfo.familyId
				});
			} finally {
				this.tableLoading = false;
			}
		},
		changePosition(type) {
			switch (type) {
				case '职族':
					this.officeInfo.level = '';
					this.officeInfo.officeCode = '';
					this.officeInfo.dutyIllustrate = '';
					this.positionLevelList = [];
				/* fall throught*/
				case '职级':
					this.levelAndCodeId = null;
					this.positionList = [];
					if (type === '职级') {
						this.getPositionList({
							familyId: this.officeInfo.familyId,
							officeCode: this.officeInfo.officeCode,
							level: this.officeInfo.level
						});
					}
				/* fall throught*/
				default:
					break;
			}
		},
		async getPositionFamilyList(params) {
			return Position.getPositionFamilyList(params).then((res) => {
				if (res._responseStatusCode === 0) {
					this.positionFamilyList = res;
					return true;
				}
				return false;
			});
		},
		async getPositionLevelListByFamily(params) {
			return Position.getPositionLevelListByFamily(params).then((res) => {
				if (res._responseStatusCode === 0) {
					this.positionLevelList = res;
					return true;
				}
				return false;
			});
		},
		async getPositionList(params) {
			return Position.getPositionList(params).then((res) => {
				if (res._responseStatusCode === 0) {
					this.positionList = res;
					return true;
				}
				return false;
			});
		},
		getRowKey(row) {
			return row.id;
		},
		downFile(file) {
			const url = `${location.origin}/v1/common/downLoad?exportparam=${encodeURIComponent(
				JSON.stringify({ fileName: file.name, bucketName: file.bucketName, key: file.key, httpUrl: file.httpUrl || file.url })
			)}`;

			window.open(url);
		},
		cancel() {
			this.$emit('cancel');
		},
		handleSelectChange(section, row) {
			this.$refs.Table.clearSelection();
			this.$refs.Table.toggleRowSelection(row, true);
			this.selectList = section;
		},
		selectPositoinLevel(selectedList) {
			if (selectedList.length > 0) {
				this.officeInfo.officeCode = selectedList[0].officeCode;
				this.officeInfo.level = selectedList[0].level;
				this.officeInfo.dutyIllustrate = selectedList[0].dutyIllustrate;
				this.dialogVisible = false;
				this.changePosition('职级');
			} else {
				this.$message({
					type: 'warning',
					message: '请选择!'
				});
			}
		},
		// selectPositionLevel (selectedList) {
		//   this.dialogVisible = false;
		//   if (selectedList.length > 0) {
		//     this.levelAndCodeId = selectedList[0].id;
		//     let row = selectedList[0].row;
		//     this.positionLevelTxt = makePositionLevelTxt(row);
		//   }
		// },
		makeFileList(json) {
			try {
				const fileList = JSON.parse(json);

				return fileList || [];
			} catch (err) {
				return [];
			}
		},
		submit() {
			if (!this.levelAndCodeId) {
				this.$message({
					message: '请选择职级评估!',
					type: 'error'
				});
				return;
			}
			//
			this.$confirm('确认要提交本次评价?', '提示', { type: 'warning' }).then(() => {
				let evaluationSubmitRequestList = [];

				try {
					evaluationSubmitRequestList = this.evaluationSubmitRequestList();
					if (this.$route.params.type === 'self' && (!this.accessoryUrl || this.accessoryUrl.length === 0)) {
						throw new RequiredError('必须上传自辩答述报告!');
					}
				} catch (err) {
					if (err instanceof RequiredError) {
						this.$message.error(err.message);
					}
					return;
				}
				this.pageLoaing = true;
				submitEvaluation({
					assessorResultId: this.id,
					levelAndCodeId: this.levelAndCodeId,
					reason: this.reason,
					accessoryUrl: this.accessoryUrl ? JSON.stringify(this.accessoryUrl) : '[]',
					evaluationSubmitRequestList
				})
					.then((res) => {
						this.pageLoaing = false;
						if (res._responseStatusCode === 0) {
							this.$message.success('评价成功!');
							const h = this.$createElement;

							this.$msgbox({
								title: '',
								showClose: false,
								customClass: 'evaluate-p',
								message: h('div', { class: 'evaluate' }, [
									h('div', { class: 'evaluate_img' }, [h('p', { style: 'font-size:20px' }, `${this.title}`)]),
									h('div', { class: 'evaluate_text' }, [
										h('h4', null, '人岗匹配评估结果'),
										h('p', null, [h('em', null, '职位'), h('span', null, `${res.score}`)]),
										h('p', { style: 'transform:translateX(-5px)' }, [h('em', null, '职等'), h('span', null, `${res.jobEtc}`)])
									])
								]),
								showCancelButton: false,
								showConfirmButton: false,
								confirmButtonText: '确定',
								callback: () => {
									this.$router.replace('/staff/match_position');
								}
							});

							//       // this.$alert(
							//       //   `<b>本次评价 成绩:</b> ${res.score}  <b>职等:</b> ${res.jobEtc}`,
							//       //   "人岗匹配评估结果",
							//       //   {
							//       //     dangerouslyUseHTMLString: true,
							//       //     callback: () => {
							//       //       this.isEvaluate = false;
							//       //       this.$router.replace("/staff/match_position");
							//       //     }
							//       //   }
							//       // );
						}
					})
					.catch(() => {
						this.pageLoaing = false;
					});
			});
		},
		handleUploaded(res, file, filelist, item) {
			this.uploadQueue.shift();
			if (res.code === 0) {
				this.$message.success('上传成功');
				const resFileMeta = {
					name: res.data.fileName,
					size: file.size,
					url: res.data.httpUrl,
					bucketName: res.data.bucketName,
					key: res.data.key
				};

				item.fileList.push(resFileMeta);
			} else {
				this.$notify({
					type: 'error',
					message: '上传失败'
				});
				filelist.pop();
			}
		},
		handleExceed(files, filelist) {
			if (files.length > 3 || filelist.length === 3 || files.length + filelist.length > 3) {
				this.$message({
					message: '上传文件数量不能超过三个',
					type: 'warning'
				});
			}
		},
		async getPositionLevels() {
			return getPositionLevelList(this.positionLevelParams).then((res) => {
				this.positionLevelDatas = res.list;
				this.totalPage = res.totalPage;
			});
		},
		handleBeforeUpload(file) {
			this.uploadQueue.push(1);
			return file;
		},
		handleRemoveFile(file, filelist, item) {
			if (file.status === 'uploading') {
				this.uploadQueue.pop();
			}
			let fileKey = '';

			if (file.response) {
				fileKey = file.response.data.key;
			} else {
				fileKey = file.key;
			}
			const index = item.fileList.findIndex((v) => v.key === fileKey);

			if (index !== -1) {
				item.fileList.splice(index, 1);
			}
		},
		evaluationSubmitRequestList(type = 'submit') {
			let evaluationSubmitRequestList = [];

			this.formData.forEach((v) => {
				v.indicatorList.forEach((cv) => {
					cv.fileList = cv.fileList || [];
					if (type === 'submit') {
						if (!cv.selectedOptionId) {
							evaluationSubmitRequestList = [];
							throw new RequiredError(`必须选择 ${cv.indicatorName}!`);
						}
						if (this.$route.params.type === 'self' && cv.requiredExample && !cv.reason) {
							evaluationSubmitRequestList = [];
							throw new RequiredError(`需为 ${cv.indicatorName} 提供举证说明!`);
						}
					}
					evaluationSubmitRequestList.push({
						indicatorId: cv.indicatorId,
						selectedOptionId: cv.selectedOptionId,
						reason: cv.reason,
						fileList: cv.fileList.map((file) => JSON.stringify([file]))
					});
				});
			});
			return evaluationSubmitRequestList;
		},
		save() {
			const evaluationSubmitRequestList = this.evaluationSubmitRequestList('save');

			this.pageLoaing = true;
			saveEvaluation({
				assessorResultId: this.id,
				levelAndCodeId: this.levelAndCodeId,
				evaluationSubmitRequestList,
				accessoryUrl: JSON.stringify(this.accessoryUrl),
				reason: this.reason
			})
				.then((res) => {
					this.pageLoaing = false;
					if (res.code === 0) {
						this.$message.success('保存成功!');
						this.getEvaluationForm(this.id);
					}
				})
				.catch(() => {
					this.pageLoaing = false;
				});
		},
		changeRadio(option, problem) {
			if (option.requiredStatus === 1) {
				this.$set(problem, 'requiredExample', true);
			} else {
				this.$set(problem, 'requiredExample', false);
			}
		},
		setEvaluationForm(res) {
			this.formData = res.classList || [];
			this.levelAndCodeId = res.levelAndCodeId || null;
			this.staffResultId = res.staffResultId;
			this.reason = res.reason;
			this.otherEvaluationList = res.sumOtherEvaluationList;
			this.nativeFileList = JSON.parse(JSON.stringify(res.accessoryUrl));
			this.accessoryUrl = res.accessoryUrl || [];
			this.officeInfo = res.officeInfo || {
				familyId: null,
				level: null,
				officeCode: null,
				dutyIllustrate: null
			};
			this.positionLevelTxt = makePositionLevelTxt(res.officeInfo);
			this.formData.forEach((v) => {
				v.indicatorList.forEach((cv) => {
					if (!cv.evaluationSaveData || !cv.evaluationSaveData.fileList) {
						this.$set(cv, 'fileList', []);
						this.$set(cv, 'nativeFileList', []);
					} else {
						cv.evaluationSaveData.fileList = cv.evaluationSaveData.fileList || [];
						this.$set(cv, 'fileList', cv.evaluationSaveData.fileList);
						this.$set(
							cv,
							'nativeFileList',
							cv.evaluationSaveData.fileList.map((file) => ({
								name: file.name,
								key: file.key,
								url: file.url,
								size: file.size,
								bucketName: file.bucketName
							}))
						);
					}
					if (!cv.evaluationSaveData || !cv.evaluationSaveData.reason) {
						this.$set(cv, 'reason', '');
					} else {
						this.$set(cv, 'reason', cv.evaluationSaveData.reason);
					}
					if (!cv.evaluationSaveData || !cv.evaluationSaveData.selectedOptionId) {
						this.$set(cv, 'selectedOptionId', null);
					} else {
						this.$set(cv, 'selectedOptionId', cv.evaluationSaveData.selectedOptionId);
					}
				});
			});
		},
		async getEvaluationForm(id) {
			return getEvaluationForm({ assessorResultId: id }).then((res) => {
				if (res._responseStatusCode === 0) {
					this.setEvaluationForm(res);
				}
			});
		}
	}
};
</script>
<style lang="scss">
@import '../../styles/judge-page.scss';
.rating-page {
	.link-text {
		padding: 4px 0;
	}
	.fj-icon {
		max-width: 250px;
		span {
			display: inline-block;
			width: 80%;
			overflow: hidden;
			margin-left: 5px;
			text-overflow: ellipsis;
		}
	}
	.fj-icon::before {
		vertical-align: super;
	}
}
.hidden-popper {
	display: none;
}
.reply-report {
	.rating-item {
		margin-top: 24px;
	}
	.el-upload__tip {
		padding-left: 4px;
	}
	.el-upload-list {
		margin: 0 40px 20px;
	}
}

.evaluate-p {
	border: none;
	overflow: visible;
	.el-message-box__content {
		padding: 0;
	}
	.el-message-box__header {
		background: rgb(128, 128, 128);
		padding: 0;
		.el-message-box__headerbtn {
			border: 1px solid #fff;
			top: -26px;
			right: -21px;
			border-radius: 50%;
			.el-message-box__close {
				color: #fff;
			}
		}
	}
}

.evaluate {
	height: 260px;
	border-radius: 4px;
	.evaluate_img {
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		width: 100%;
		height: 40%;
		background: url('/static/images/evaluate.png') no-repeat center center;
		background-size: 420px auto;
		display: flex;
		align-items: center;
		text-indent: 2em;
		color: #fff;
		font-weight: 700;
		position: relative;
		span {
			border: 1px solid #000;
			text-indent: 0;
			font-weight: normal;
			border-radius: 50%;
			text-align: center;
			width: 20px;
			height: 20px;
			position: absolute;
			right: -22px;
			top: -22px;
			cursor: pointer;
		}
	}

	.evaluate_text {
		background: #fff;
		height: 60%;
		display: flex;
		// align-items:
		flex-wrap: wrap;
		justify-content: space-around;
		h4,
		p {
			width: 100%;
			text-align: center;
			color: rgb(144, 49, 238);
			font-size: 16px;
		}

		h4 {
			padding: 14px 0;
			font-size: 18px;
			font-weight: 700;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		p {
			font-weight: 700;
			em {
				font-weight: 700;
			}
			span {
				color: #f00;
				padding-left: 6px;
			}
		}
	}
}
</style>
<style lang="scss">
.judgeBoxs {
	background: #fff;
	padding: 7px 15px;
	margin: 0 14px 0 7px;
}
.dutyIllustrate {
	.el-form-item__content {
		width: 77%;
		transform: translateY(10px);
		line-height: 20px;
	}
}
.rating-page {
	.el-upload-list {
		.el-upload-list__item {
			width: 30%;
			padding: 5px;
			background-color: #f4f4f5;
			label i,
			.el-icon-close {
				transform: translate(-5px, 5px);
			}
		}
		.el-upload-list__item:hover {
			background-color: #f5f7fa;
		}
	}
	.file-pre-download {
		height: 50px;
	}
	.text-high-light {
		display: inline-block;
		transform: translateY(-5px);
	}
}
</style>
<style lang="scss" scoped>
::v-deep.el-upload-list__item .el-progress {
	top: 24px;
	left: 20px;
	width: 90%;
}
</style>
